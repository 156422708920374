import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				HAVEN RETREAT
			</title>
			<meta name={"description"} content={"У Haven Retreat ми створили місце, де розкіш і природа поєднуються. Наші ексклюзивні спа-програми та релаксаційні процедури дозволять вам зануритися у стан спокою та насолоди. Відновіть енергію тіла та душі в атмосфері бездоганної гармонії та затишку."} />
			<meta property={"og:title"} content={"HAVEN RETREAT"} />
			<meta property={"og:description"} content={"У Haven Retreat ми створили місце, де розкіш і природа поєднуються. Наші ексклюзивні спа-програми та релаксаційні процедури дозволять вам зануритися у стан спокою та насолоди. Відновіть енергію тіла та душі в атмосфері бездоганної гармонії та затишку."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header23>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					ПРО НАС
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Ласкаво просимо до Haven Retreat – місця, де природа і розкіш поєднуються для створення ідеальних умов для вашого відпочинку. Ми створили простір, де кожен куточок наповнений спокоєм, гармонією і комфортом, аби допомогти вам віднайти свій внутрішній баланс.
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="1rem 0px 0px 0px"
				>
					Наше унікальне поєднання сучасних оздоровчих практик і природних ресурсів робить Haven Retreat ідеальним місцем для тих, хто прагне втекти від щоденного стресу та наповнити своє життя новою енергією.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10:47:40.767Z"
					width="100%"
					max-height="auto"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/fACE-SCRUB.jpg?v=2024-09-20T10%3A47%3A40.767Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="24px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				md-display="flex"
			>
				<Image
					height="auto"
					src="https://uploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10:47:40.771Z"
					lg-width="320px"
					lg-height="320px"
					width="100%"
					min-width="500px"
					srcSet="https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/Top-7-Factors-to-Consider-When-Choosing-a-Spa.jpg?v=2024-09-20T10%3A47%3A40.771Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="auto"
				display="flex"
				justify-content="center"
				md-width="100%"
				align-items="flex-end"
			>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px" justify-content="flex-end">
					<Box display="flex" align-items="center" width="auto" />
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Починаючи з невеликого оздоровчого центру, Haven Retreat виріс у комплекс, який пропонує безліч можливостей для вашого відпочинку та відновлення. Наші сучасні методи догляду і класичні практики створюють ідеальне поєднання для вашого благополуччя.
						</Text>
					</Box>
				</Box>
				<Box align-items="center" margin="0px 0px 16px 0px" display="flex" justify-content="flex-end">
					<Box display="flex" align-items="center" width="auto" />
					<Box display="flex" align-items="center" width="60%" />
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" md-padding="20px 0 12px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					width="100%"
				>
					Відкрийте для себе Haven Retreat
				</Text>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10:47:40.780Z"
					max-height="400px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10%3A47%3A40.780Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						У Haven Retreat ми дбаємо про ваше здоров’я на кожному етапі вашого перебування. Насолоджуйтесь нашими спа-послугами, розслабляйтесь у термальних басейнах або проведіть час на свіжому повітрі в наших ландшафтних садах. Ми створили ідеальні умови для відпочинку та перезавантаження.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10:47:40.776Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed228be778710018d371a1/images/65e1e374f5a11a2b9c3028f0_618d59a5d94a71d361c99494_Mercure-Sheffield-Kenwood-Hall-Hotel-Spa-Leisure-Club-and-spa-Breaks-1-1400x788.jpeg?v=2024-09-20T10%3A47%3A40.776Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						Приєднуйтесь до нас у Haven Retreat для вашого ідеального відпочинку. Незалежно від того, чи шукаєте ви приватного відпочинку або проводите час з близькими, ми гарантуємо унікальний досвід, який надихне вас на нові звершення та наповнить вас енергією.
					</Text>
					<Button
						background="--color-green"
						border-radius="13px"
						min-width="200px"
						max-width="100%"
						margin="0px auto 0px auto"
						text-align="center"
						type="link"
						text-decoration-line="initial"
						href="/menu"
					>
						ПОСЛУГИ
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer123 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});